import axios from 'axios';

interface ISetAuthTokenArgs {
  accessToken: string;
  idToken?: string;
}

export const setAuthToken = ({ accessToken }: ISetAuthTokenArgs) => {
  axios.defaults.withCredentials = true;
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  // if (idToken) {
  //   axios.defaults.headers.common['authid'] = `Bearer ${idToken}`;
  // }

  axios.defaults.headers.common['xc-portal-source-client'] =
    'SpectrumOnDemand@2.0.0';
};

export const removeAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
  // delete axios.defaults.headers.common['authid'];
};
