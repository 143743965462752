import packageJson from '../../package.json';

const getEnvName = () => {
  if (typeof window === 'undefined') {
    return null;
  } else if (window.location.host.split('.')[0] === 'ondemand') {
    return 'prod';
  } else {
    return 'staging';
  }
};

export default {
  domain: 'video',
  customer: 'Charter',
  appName: 'SpectrumOnDemand',
  appType: 'Web',
  appVersion: packageJson.version,
  deviceType: 'webBrowser',
  requirementsVersion: '2.547',
  msgTriggeredBy: 'user',
  startSession: 'SpectrumOnDemand_startSession',
  settings: {
    logLevel: 'error',
  },
  appEnvName: getEnvName(),
};
