import { useEffect, useState } from 'react';
import { authConfig } from '../utils/auth/authConfig';
import axios from 'axios';
import { IUser } from '../@types';

const validateSession = async (user: IUser | null, logout: () => Promise<void>) => {
  if (!user) return false;

  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.GATSBY_IDM_API_URL}/auth/oauth/v2/validateSession`,
      headers: {
        'x-client-id': authConfig.clientId,
        'x-access-token': user.access_token,
      },
    });

    const { isValidSession } = response.data;
    // Clears existing user data if it exists and there isn't a valid session
    // This can occur if a user logs out from another website, which invalidates the cookies and tokens
    if (!isValidSession && user) {
      await logout();
      return false;
    }
    return isValidSession;
  } catch (error: any) {
    console.log('Validate Session API Error: ', error);
    return false;
  }
};

const useValidateSession = (user: IUser | null, logout: () => Promise<void>) => {
  const [isValidSession, setIsValidSession] = useState(false);

  useEffect(() => {
    (async () => {
      const isSessionValid = await validateSession(user, logout);
      setIsValidSession(isSessionValid);
    })();
  }, [user]);

  return { isValidSession };
};

export default useValidateSession;
