import React, {
  useState,
  useEffect,
  createContext,
  FunctionComponent,
  PropsWithChildren
} from 'react';

// Analytics
import AnalyticsService from './Analytics';
import analyticsConfig from './analytics-config';
import packageJson from '../../package.json';

export const AnalyticsContext = createContext<any>({ analytics: null });

export const AnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [analytics, setAnalytics] = useState<AnalyticsService | null>(null);

  useEffect(() => {
    const newAnalytics = new AnalyticsService(
      analyticsConfig,
      packageJson.version
    );

    setAnalytics(newAnalytics);
  }, []);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
