// Use this package for dev
// import Helix from '@charter/helix/build/video';

// Use this package for prod
import Helix from '@charter/helix/build/video/index.min';

const state = {
  analytics: null,
  defaultData: null,
  config: {},
};

const isBrowser = typeof window !== `undefined`;

export default class AnalyticsService {
  constructor(config, version) {
    try {
      if (config && version) {
        state.config = config;
        if (state.config) {
          state.config.appVersion = version;
        }
      } else {
        console.error(
          `Analytics Service | Error - No Config or Version provided.`
        );
        return;
      }
    } catch (e) {
      console.error(`Analytics Service | Error on Constructor : ${e}`);
      return;
    }

    this.init = this.init.bind(this);
    this.track = this.track.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.init();
  }

  init() {
    if (!isBrowser) {
      return null;
    }

    try {
      Helix.useDefaultStorage();

      let endpoint;
      if (window.location.host.split('.')[0] === 'ondemand') {
        endpoint = 'https://collector.pi.spectrum.net/api/collector?bulk=true';
      } else {
        endpoint = 'https://collector.pi-charter.net/api/collector?bulk=true';
      }

      Helix.set('endpoint', endpoint);

      Helix.init(state.config)
        .then(() => {
          if (Helix.get('currentState') === 'blank') {
            Helix.trackStartSession(state.config.startSession, {
              drmEnabled: true,
            });
          }
        })
        .catch((e) => console.error(e, 'INIT ERROR'));
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @doc method
   * @name analytics#getAnalytics
   * @methodOf analytics
   * @returns {AnalyticsService}
   * @description Gets/Inits the AnalyticsService
   */
  static getAnalytics(config, version) {
    if (!isBrowser) {
      return null;
    }
    if (!state.analytics) {
      state.analytics = new AnalyticsService(config, version);
    }
    return state.analytics;
  }

  /**
   * @doc method
   * @name analytics#getVisitId
   * @methodOf analytics
   * @returns {}
   * @description Gets the Current VisitId from the LocalStorage
   */
  static getVisitId() {
    if (!isBrowser) {
      return null;
    }
    let visitId = null;
    const libState = JSON.parse(window.localStorage.getItem('library-state'));
    if (libState && libState?.hasOwnProperty('model')) {
      visitId = libState.model.visit.visitId; // eslint-disable-line
    }
    return visitId;
  }

  /**
   * @doc method
   * @name analytics#track
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track
   */
  track(id, adHocData, options) {
    if (!isBrowser) {
      return null;
    }
    try {
      Helix.track(id, adHocData, options);
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }

  /**
   * @doc method
   * @name analytics#trackPageView
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track with a Complete PageView Cycle (Init-Completed). To be used when no Page load time calculation is required.
   */
  trackPageView(pageName, adHocData, pageUrl) {
    if (!isBrowser) {
      return null;
    }
    try {
      for (const msgEventType of ['pageViewInit', 'pageViewCompleted']) {
        Helix.track('pageView', {
          msgEventType,
          currPageUrl: pageUrl || window.location.href,
          currPageName: pageName,
          currPageAppSection:
            pageName === 'tvShows'
              ? 'tvShows'
              : pageName === 'movies'
              ? 'movies'
              : 'Spectrum On Demand',
          ...adHocData,
        });
      }
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }

  // trackApiCalls() {
  //   this.apiService.postRequest.pipe(takeUntil(this.destroy$)).subscribe((apiCallData) => {
  //     const trackData = this.apiDefaultService.processSuccess(apiCallData);
  //     this.track('apiCall', trackData);
  //   });

  //   this.apiService.errorRequest.pipe(takeUntil(this.destroy$)).subscribe((apiCallData) => {
  //     let trackData = this.apiDefaultService.processError(apiCallData);
  //     if (trackData.appApiResponseCode === HttpStatusCode.Ok.toString()) {
  //       this.track('apiCall', trackData);
  //     } else {
  //       this.track('apiCall', trackData);
  //     }
  //   });
  // }
}
