import { TAuthConfig } from './authTypes';

export const authConfig: TAuthConfig = {
  clientId: 'spectrum_on_demand',
  tokenEndpoint: `${process.env.GATSBY_IDM_API_URL}/auth/oauth/v2/token`,
  authorizationEndpoint: `${process.env.GATSBY_IDM_PORTAL_URL}/login`,
  redirectUri: process.env.GATSBY_IDM_REDIRECT_URI || '',
  logoutEndpoint: '',
  logoutRedirect: '',
  accountType: 'RESIDENTIAL',
};
