import { generateCodeChallenge, generateCodeVerifier } from './pkceUtils';
import { TAuthConfig, TTokenData } from './authTypes';

import { authConfig } from './authConfig';

import axios from 'axios';

export const EXPIRED_REFRESH_TOKEN_ERROR_CODES = [];

// handle unknown error typescript issue
function getErrorMessage(error: unknown): string {
  if (error instanceof Error) return error.message;
  return String(error);
}

const reportError = ({ message }: { message: string }) => {
  console.error(message);
  throw message;
};

// interface ILoginProps {
//   url: string;
// }

export async function login(contextToken: string) {
  // Create and store a random string in localStorage, used as the 'code_verifier'
  const codeVerifier = generateCodeVerifier();
  window.localStorage.setItem('PKCE_code_verifier', codeVerifier);

  // Hash and Base64URL encode the code_verifier, used as the 'code_challenge'
  generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    // Set query parameters and redirect user to OAuth2 authentication endpoint
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: authConfig.clientId,
      scope: authConfig.scope || '',
      redirect_uri: window.location.origin,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      account_type: 'RESIDENTIAL',
      nonce: '123456789',
      dark: 'true',
      state: encodeURIComponent(
        JSON.stringify({
          prevPath: window.location.pathname + window.location.search,
        })
      ),
    });

    if (contextToken) {
      const contextTokenValue = contextToken.split('=')[1];
      params.set('context_token', contextTokenValue);
    }

    // Call any preLogin function in authConfig
    if (authConfig?.preLogin) authConfig.preLogin();
    window.location.replace(
      `${authConfig.authorizationEndpoint}?${params.toString()}`
    );
  });
}

async function postWithFormData(
  tokenEndpoint: string,
  formData: any
): Promise<any> {
  try {
    const response = await axios(tokenEndpoint, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });

    if (response.status !== 200) {
      throw response.data.error_description;
    }
    return response.data;
  } catch (error) {
    reportError({ message: getErrorMessage(error) });
  }
}

export const fetchTokens = (authConfig: TAuthConfig): Promise<any> => {
  /*
   *  This function is called after the user has authenticated with the OAuth2 server.
   *  This code will now be exchanged for Access- and Refresh Tokens.
   */

  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code');
  const codeVerifier = window.localStorage.getItem('PKCE_code_verifier');

  if (!authCode) {
    throw new Error(
      "Parameter 'code' not found in storage. \nHas authentication taken place?"
    );
  }
  if (!codeVerifier) {
    throw new Error(
      "Can't get tokens without the CodeVerifier. \nHas authentication taken place?"
    );
  }

  const formData = new URLSearchParams();
  formData.append('grant_type', 'authorization_code');
  formData.append('code', authCode);
  formData.append('scope', authConfig.scope || '');
  formData.append('client_id', authConfig.clientId);
  formData.append('redirect_uri', authConfig.redirectUri);
  formData.append('code_verifier', codeVerifier);
  formData.append('account_type', authConfig.accountType);

  return postWithFormData(authConfig.tokenEndpoint, formData);
};

export interface IFetchWithRefreshTokenArgs {
  authConfig: TAuthConfig;
  refreshToken: string;
}

export const fetchWithRefreshToken = ({
  authConfig,
  refreshToken,
}: IFetchWithRefreshTokenArgs) => {
  const formData = new URLSearchParams();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refreshToken);
  formData.append('client_id', authConfig.clientId);
  return postWithFormData(authConfig.tokenEndpoint, formData);
};

/**
 * Decodes the the base64 encoded JWT. Returns a TToken that contains the x-charter-session id.
 */
export const decodeToken = (token: string): TTokenData => {
  const base64Url = token.split('.')[0];
  const jsonPayload = decodeURIComponent(atob(base64Url));
  return JSON.parse(jsonPayload);
};

/**
 * Check if the Access Token has expired by looking at the 'is_expired' field.
 * Will return True if the token has expired, OR there is less than 10min until it expires.
 */

export const tokenExpired = (exp: number | null): Boolean => {
  if (!exp) return true;
  const bufferTimeInMilliseconds = 10 * 60 * 1000; // minutes * seconds * toMilliseconds
  const expirationTimeWithBuffer = new Date(
    exp * 1000 - bufferTimeInMilliseconds
  );
  return new Date() > expirationTimeWithBuffer;
};
